<template>
    <div id="production" :class="[$mq]">
        <div id="sidebar">
            <Sidebar :categories="categories" :date="date" :summary="summary" @selectCategory="selectCategory"></Sidebar>
        </div>

        <div id="board">
            <div class="topbar">TOPBAR</div>
            <div class="main-content">
                <div class="table">
                    <div class="products">
                        <div class="item" v-for="product in products" :key="product.id">
                            <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                            <div class="name">{{ product.name }}</div>
                        </div>
                    </div>
                    <div class="forecast">
                        <div class="row" v-for="product in products" :key="product.id">
                            <div class="cell" :class="{ current: index == current_time }" v-for="(cell, index) in product.forecast" v-if="index >= visible_time[0] && index <= visible_time[1]">
                                <span class="value">{{ cell.v ? cell.v : '-' }}</span
                                ><span v-if="cell.i" class="increase" :class="{ positive: cell.i > 0, negative: cell.i < 0 }">{{ cell.i ? Math.abs(cell.i) : '-' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="summary">
                        <div class="item" v-for="product in products" :key="product.id">
                            <div class="value">{{ product.summary }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '../components/domain/production/sidebar.vue'

export default {
    components: { Sidebar },
    name: 'Production',
    data() {
        return {
            timers: [],
            selectedCategory: false,
            date: moment(),
            current_time: 2,
            visible_time: [0, 6],
            summary: {
                date: moment().format('ddd YYYY-MM-DD'),
                base: 43,
                forecast: 5652,
                current: 73
            }
        }
    },
    computed: {
        categories() {
            return this.$store.getters['production/getCategories']
        },
        category() {
            return this.$store.getters['production/getSelectedCategory']
        },
        products() {
            let data = this.$store.getters['production/getProducts']
            let products = []
            for (var idx in data) {
                var product = data[idx]
                let show = false
                if (!this.category) {
                    show = true
                } else if (this.category && product.category == this.category.id) {
                    show = true
                }

                if (show) {
                    products.push(product)
                }
            }
            return products
        }
    },
    methods: {
        load() {
            let self = this

            this.$overlay.loading()

            this.$store.dispatch('production/loadData').then(() => {
                self.selectCategory(false)
                self.$overlay.hide()
            })
        },
        selectCategory(category) {
            var self = this
            self.$overlay.loading()
            this.$store.commit('production/selectCategory', category)
            self.selectedCategory = category
            self.$overlay.hide()
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
#production {
    display: flex;
    x-justify-content: space-around;
    x-align-items: stretch;
    width: 100%;

    #sidebar {
        width: 325px;
        flex: 0 0 325px;
        height: 100%;
        background-color: #fff;

        position: absolute;
    }
    #board {
        padding: 8px;
        border-radius: 4px;
        width: calc(100% - 325px);
        flex: 0 0 calc(100% - 325px);
        position: absolute;
        left: 325px;
        height: 100%;
        min-height: 0;
        min-width: 0;

        .topbar {
            width: 100%;
            height: 50px;
            background-color: #ccc;
        }
        .main-content {
            width: 100%;
            height: calc(100% - 50px);
            // position: absolute;
            // height: calc(100% - 50px - 16px);

            .table {
                display: flex;

                .products {
                    width: 300px;
                    width: 30%;

                    .item {
                        width: 100%;
                        height: 75px;
                        display: flex;
                        align-items: center;

                        .icon {
                            width: 50px;
                            height: 50px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: 90%;
                            display: inline-block;
                        }
                        .name {
                            display: inline-block;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(m);
                        }
                    }
                }

                .forecast {
                    width: calc(100% - 300px - 100px);
                    width: 55%;

                    .row {
                        display: flex;
                        gap: 16px;
                        width: 100%;
                        height: 75px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .cell {
                            width: 50px;
                            height: 60px;
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            position: relative;

                            .value {
                                font-family: $text-bold;
                                @include font-size(l);
                                display: block;
                            }
                            .increase {
                                display: block;
                                position: absolute;
                                bottom: 0px;
                                @include font-size(xs);
                                display: flex;
                                align-items: center;

                                &:before {
                                    display: inline-block;
                                    content: '';
                                    width: 0;
                                    height: 0;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-radius: 3px;
                                    margin-right: 2px;
                                }

                                &.positive {
                                    &:before {
                                        border-bottom: 6px solid green;
                                    }
                                }
                                &.negative {
                                    &:before {
                                        border-top: 6px solid #f00;
                                    }
                                }
                            }

                            &.current {
                                background-color: $color-warning-500;
                                border-color: $color-warning-500;
                                color: #fff;

                                .value {
                                    color: #fff;
                                }
                                .increase {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
                .summary {
                    width: 100px;
                    width: 15%;
                    text-align: center;
                    .item {
                        width: 100%;
                        height: 75px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .value {
                            font-family: $text-bold;
                            @include font-size(l);
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
</style>
